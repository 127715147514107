/* html[data-theme='dark'] {
  background: #121212;
} */

.code-border {
  border-radius: '.375rem';
  border-width: '4px';
  border-style: 'solid';
  border-color: '#e5e7eb';
}
